<template>
  <!-- 悦淘促销活动 -->
  <div class="bonus-promotion">
    <!-- 商品列表 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="bonus-promotion-banner">
        <div><img :src="bannerUrl" alt=""></div>
      </div>
      <div class="bonus-promotion-transition">
        <div><img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-06-03/18/yuelvhuiyd9tzLjkRE1622715000.png" alt=""></div>
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="recommend_flow_list">
          <skeleton v-if="goodsList.length === 0" type="list" />
          <Waterfalls
            v-else
            id="dataList"
            :col="2"
            :list="goodsList"
          >
            <template slot-scope="{ data }">
              <div class="recommended_item" @click="handleClick(data)">
                <div v-if="data.markerUrl" class="markerUrlIcon">
                  <img :src="data.markerUrl">
                  <!-- {{data.markerUrl}} -->
                </div>

                <div class="recommended_item_img">
                  <imgLoading :src="data.goodCover" />
                </div>

                <div class="recommended_item_box">
                  <div class="tit">
                    {{ data.goodName }}
                  </div>
                  <div class="money">
                    <em>¥</em>{{ data.goodVipPrice }}
                    <span>
                      <em>¥</em>
                      <span>{{ data.goodPrice }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </Waterfalls>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import ImgLoading from '@/component/imgLoading'
import Waterfalls from '@/component/Waterfalls'
import skeleton from '@/component/skeleton/index'
import { fetchDivideBonus } from '@/services/activity/divide-bonus'

import { List, PullRefresh, Tab, Tabs } from 'vant'
import Vue from 'vue'
import { isYTApp } from '@/utils/common/validate'
import { wxConfigInit } from '@/utils/wx-api'
import { delUrlParam } from '@/utils/common/utils'

const isYtApp = isYTApp()
Vue.use(Tabs).use(Tab).use(List).use(PullRefresh)
export default {
  name: 'BonusPromotion',
  components: {
    Waterfalls,
    skeleton,
    ImgLoading
  },
  data() {
    return {
      finished: false,
      loading: false,
      refreshing: false,
      error: false,
      bannerUrl: '0',
      goodsList: [],
      listQuery: {
        page: 1,
        pageSize: 10
      },
      shareInfo: {}
    }
  },
  computed: {
  },
  created() {
    if (isYtApp) {
      this.handleAppShare(0)
      window.RNshareDataChange = this.handleShowShare
    }
  },
  mounted() {
    this.getPromotionGoodsList()
  },
  methods: {
    /**
     * 触发打开App分享设置
     * 2021-05-28 16:31:42
     * @author SnowRock
     */
    handleAppShare(type) {
      this.$store.dispatch('RNrouter', {
        eventName: 'isShowShare',
        isShow: type
      })
    },
    onLoad() {
      this.listQuery.page++
      this.getPromotionGoodsList()
    },
    /**
     * 下拉刷新
     */
    onRefresh() {
      // 清空列表数据
      this.finished = false
      this.goodsList = []
      this.listQuery.page = 0

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    /**
     * 分享参数设置
     * 2021-06-03 17:40:39
     * @author SnowRock
     */
    async handleShowShare() {
      const url = window.location.href
      const shareInfo = {
        link: delUrlParam(delUrlParam(url, 'mid'), 'token'),
        ...this.shareInfo
      }
      if (isYtApp) {
        const appShare = {
          eventName: 'share',
          image: shareInfo.imgUrl, // 分享标题
          title: shareInfo.title,
          des: shareInfo.desc,
          url: shareInfo.link
        }
        this.$store.dispatch('RNrouter', appShare)
      } else {
        await wxConfigInit(shareInfo)
      }
    },
    /**
     * 获取促销商品列表
     * 2021-06-03 16:24:21
     * @author SnowRock
     */
    async getPromotionGoodsList() {
      try {
        const res = await fetchDivideBonus(this.listQuery)
        const { code, data } = res
        this.loading = false
        if (code === 200) {
          if (this.refreshing) {
            this.goodsList = []
            this.refreshing = false
          }

          this.goodsList = data.list
          this.bannerUrl = data.bannerUrl
          this.finished = data.list.length < 10

          this.shareInfo = {
            title: data.shareInfo.title || '',
            desc: data.shareInfo.desc || '',
            imgUrl: data.shareInfo.imgUrl || ''
          }

          if (this.listQuery.page === 1) {
            if (!isYtApp) {
              await this.handleShowShare()
            } else {
              this.handleAppShare(1)
            }
          }
        } else {
          this.error = true
          this.listQuery.page--
        }
      } catch (e) {
        this.loading = false
        this.error = true
        this.listQuery.page--
      }
    },
    /**
     * 处理点击事件
     * 2021-06-03 16:20:53
     * @author SnowRock
     */
    handleClick({ goodId, skuId, productType = 1 }) {
      this.$store.commit('ScrollDateChange', {
        Scroll: 200
      })

      const obj = {
        product_type: productType,
        goodId: goodId,
        skuId: skuId
      }
      this.$store.dispatch('godetail', obj)
    }
  }
}
</script>

<style lang="less">
</style>
<style lang="less" scoped>
.bonus-promotion {
  min-height: 100vh;
  background-color: #f73f3e;
  text-align: left;
  .bonus-promotion-banner {
    width: 100%;
    height: 421px;
    position: relative;
    div {
      position: absolute;
      z-index: 0;
      top: -1px;
      left: 0;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .bonus-promotion-transition {
    width: 290px;
    height: auto;
    z-index: 23;
    position: relative;
    margin: 30px auto 10px;

    img {
      width: 290px;
      height: auto;
    }
  }
  .recommend_flow_list {
    width: 100%;
    .recommended_item {
      position: relative;
      .recommended_item_img {
        img {
          height: 100px;
        }
        // height: 170px;
      }
      .recommended_item_box {
        margin: 0 8px;
      }
      width: 100%;
      background: #fff;
      border-radius: 9px;
      margin-bottom: 9px;

      overflow: hidden;

      .tit {
        color: #191919;
        font-size: 14px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2; /*要显示的行数*/
        -webkit-box-orient: vertical;
        margin: 10px 0 5px 0;
        line-height: 20px;
        font-weight: bold;
        img {
          width: 16px;
          height: 14px;
          border-radius: 4px;
        }
      }
      .desc {
        .desc_one {
          color: red;
          font-size: 10px;
          background: rgba(212, 146, 146, 0.4);
          display: initial;
          border: 1px solid red;
          border-radius: 4px;
          padding: 0 4px;
          box-sizing: border-box;
        }
        .desc_jd {
          width: 68px;
          height: 14px;
          background: url("~@/assets/images/yhj_red.png") no-repeat;
          background-size: 100% 100%;
          font-size: 10px;
          color: #fff;
          padding: 1px 3px;
          box-sizing: border-box;
          margin-right: 12px;
        }
      }
      .money {
        font-size: 17px;
        color: #c82519;
        font-weight: 500;
        margin-top: 8px;
        margin-bottom: 4px;
        span {
          text-decoration: line-through;
          color: #bbbbbb;
          font-size: 12px;
          margin-left: 2px;
          line-height: 23px;
        }
        em {
          font-size: 12px;
          font-style: normal;
        }
      }
    }
  }
}
</style>
